// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faculty {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    h4 {
        font-size: 12px; /* You can adjust the size as per your preference */
      }
    
   
}

.faculty img {
    margin-right: 20px;
  
}

@media screen and (max-width:768px) {
    .faculty {
        flex-direction: column;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/people/Person.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB;QACI,eAAe,EAAE,mDAAmD;MACtE;;;AAGN;;AAEA;IACI,kBAAkB;;AAEtB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;AAEJ","sourcesContent":[".faculty {\r\n    display: flex;\r\n    flex-direction: row;\r\n    margin-bottom: 20px;\r\n    h4 {\r\n        font-size: 12px; /* You can adjust the size as per your preference */\r\n      }\r\n    \r\n   \r\n}\r\n\r\n.faculty img {\r\n    margin-right: 20px;\r\n  \r\n}\r\n\r\n@media screen and (max-width:768px) {\r\n    .faculty {\r\n        flex-direction: column;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
