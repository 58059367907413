import React from 'react'
import Layout from '../components/layout/Layout'
import Contact from '../components/contact/conatct'


export default function ContactPage() {

  return (

    <Layout>
      <Contact />
    </Layout>

  )
}


