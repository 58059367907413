// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .header {
    background-color:lightblue;
    color: black;
    padding: 5px;  
    display: flex;
    width: 100%;
     
    align-items: center;
    justify-content: space-between;
    padding: 1rem; /* Adjust padding as needed */

  }


  @media screen and (max-width:768px) {
    .header {
        font-size: 10px;
    }

}
  `, "",{"version":3,"sources":["webpack://./src/components/layout/Header.css"],"names":[],"mappings":";EACE;IACE,0BAA0B;IAC1B,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,WAAW;;IAEX,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa,EAAE,6BAA6B;;EAE9C;;;EAGA;IACE;QACI,eAAe;IACnB;;AAEJ","sourcesContent":["\r\n  .header {\r\n    background-color:lightblue;\r\n    color: black;\r\n    padding: 5px;  \r\n    display: flex;\r\n    width: 100%;\r\n     \r\n    align-items: center;\r\n    justify-content: space-between;\r\n    padding: 1rem; /* Adjust padding as needed */\r\n\r\n  }\r\n\r\n\r\n  @media screen and (max-width:768px) {\r\n    .header {\r\n        font-size: 10px;\r\n    }\r\n\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
