// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main{
    display: flex;
    flex-direction: column;
    background-color:white;

    width:100%;
   
}

.body{
    padding: 10px;
    line-height: 30px;
    max-height: 100vh;
    overflow: auto;
    
}


.footer{
    position: absolute;   
    bottom: 0;
    width: 100%;
}
.scrollable-div {
    width: 300px; /* Set your desired width */
    height: 200px; /* Set your desired height */
    overflow: hidden; /* Hide the scrollbar */
    border: 1px solid #ccc; /* Optional: Add a border for visual clarity */
  }`, "",{"version":3,"sources":["webpack://./src/components/layout/Main.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;;IAEtB,UAAU;;AAEd;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;;AAElB;;;AAGA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;AACA;IACI,YAAY,EAAE,2BAA2B;IACzC,aAAa,EAAE,4BAA4B;IAC3C,gBAAgB,EAAE,uBAAuB;IACzC,sBAAsB,EAAE,8CAA8C;EACxE","sourcesContent":[".main{\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color:white;\r\n\r\n    width:100%;\r\n   \r\n}\r\n\r\n.body{\r\n    padding: 10px;\r\n    line-height: 30px;\r\n    max-height: 100vh;\r\n    overflow: auto;\r\n    \r\n}\r\n\r\n\r\n.footer{\r\n    position: absolute;   \r\n    bottom: 0;\r\n    width: 100%;\r\n}\r\n.scrollable-div {\r\n    width: 300px; /* Set your desired width */\r\n    height: 200px; /* Set your desired height */\r\n    overflow: hidden; /* Hide the scrollbar */\r\n    border: 1px solid #ccc; /* Optional: Add a border for visual clarity */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
