// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-menu {
    list-style-type: none;
    padding: 0;
  }
  
  .nav-item {
    margin-bottom: 10px;
  }
  
  .sub-menu {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .sub-menu .nav-item {
    margin-bottom: 5px;
  }
  
  .bi {
    margin-right: 5px;
  }
 /* People.css */

/* Overall container */
.container {
  max-width: 800px;
  margin: 0 auto;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th {
 
  padding: 50px;
  text-align: left;
}

table td {
  border: 1px solid #ddd;
  padding: 10px;
  width: 100px;
}

/* Advisor, External Advisor, Members container */
.section-container {
  margin-bottom: 80px;
  
}

/* Faculty container */
.faculty {
  display: flex;
  align-items: center;
  
  border: 51px solid #ccc;
  padding: 20px;
}
.h4{
  padding-left: 20px;
}




.titleBold{
  font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;CACD,eAAe;;AAEhB,sBAAsB;AACtB;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA,kBAAkB;AAClB;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;;EAEE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,YAAY;AACd;;AAEA,iDAAiD;AACjD;EACE,mBAAmB;;AAErB;;AAEA,sBAAsB;AACtB;EACE,aAAa;EACb,mBAAmB;;EAEnB,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,kBAAkB;AACpB;;;;;AAKA;EACE,iBAAiB;AACnB","sourcesContent":[".nav-menu {\r\n    list-style-type: none;\r\n    padding: 0;\r\n  }\r\n  \r\n  .nav-item {\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .sub-menu {\r\n    list-style-type: none;\r\n    padding-left: 20px;\r\n  }\r\n  \r\n  .sub-menu .nav-item {\r\n    margin-bottom: 5px;\r\n  }\r\n  \r\n  .bi {\r\n    margin-right: 5px;\r\n  }\r\n /* People.css */\r\n\r\n/* Overall container */\r\n.container {\r\n  max-width: 800px;\r\n  margin: 0 auto;\r\n}\r\n\r\n/* Table styling */\r\ntable {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  margin-bottom: 20px;\r\n}\r\n\r\ntable th {\r\n \r\n  padding: 50px;\r\n  text-align: left;\r\n}\r\n\r\ntable td {\r\n  border: 1px solid #ddd;\r\n  padding: 10px;\r\n  width: 100px;\r\n}\r\n\r\n/* Advisor, External Advisor, Members container */\r\n.section-container {\r\n  margin-bottom: 80px;\r\n  \r\n}\r\n\r\n/* Faculty container */\r\n.faculty {\r\n  display: flex;\r\n  align-items: center;\r\n  \r\n  border: 51px solid #ccc;\r\n  padding: 20px;\r\n}\r\n.h4{\r\n  padding-left: 20px;\r\n}\r\n\r\n\r\n\r\n\r\n.titleBold{\r\n  font-weight: bold;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
